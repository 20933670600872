import { useState } from 'react';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

// Components
import SignEvalButton from '../SignEvalButton';

// MUI imports
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Helpers
import { deleteSession } from '../../helpers/api/callSession';
import { useTheme } from '../../hooks/useTheme';

interface deleteSessionProps {
    sessionId: string | undefined;
    sessionName: string;
}

const DeleteSession = ({ sessionId, sessionName }: deleteSessionProps) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleDelete = async () => {
        if (!sessionId) {
            console.error('sessionId is null or undefined');
            return;
        }

        const response = await deleteSession(sessionId);

        if (!response) {
            console.error('Error deleting session');
            toast.error(i18n.t('DeleteSession.deleteError'));
            handleClose();
            return;
        }

        navigate('/sessions');
        toast.success(i18n.t('DeleteSession.deleteSuccess'));
        handleClose();
    };

    return (
        <>
            <SignEvalButton id="deleteButton" variant="contained" backgroundColor="red" size="medium" endIcon={<DeleteOutlineOutlinedIcon />} onClick={handleOpen}>
                <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.delete')}</Typography>
            </SignEvalButton>
            <Modal open={isOpen} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: ' 76%',
                        bgcolor: 'background.paper',
                        borderRadius: 3,
                        boxShadow: 24,
                        p: 3
                    }}
                >
                    <DeleteOutlineOutlinedIcon sx={{ width: 75, height: 75, color: '#ADADAD' }} />
                    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mt: 2, fontSize: '1.1em', fontWeight: 'bold', textAlign: 'center', color: theme.palette.secondary.main }}>
                        {i18n.t('DeleteSession.title')}
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2, fontSize: '1em', textAlign: 'center', color: theme.palette.text.primary }}>
                        {sessionName}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                            mt: 3
                        }}
                    >
                        <SignEvalButton variant="contained" backgroundColor="blue" size="medium" onClick={handleClose}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('DeleteSession.cancel')}</Typography>
                        </SignEvalButton>
                        <SignEvalButton variant="contained" backgroundColor="red" size="medium" onClick={handleDelete}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('DeleteSession.delete')}</Typography>
                        </SignEvalButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default DeleteSession;
