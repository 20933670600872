import { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { useParams } from 'react-router-dom';
import i18n from '../i18n/i18n';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';
import InternsComponent from '../components/SingleTrainee';
import InternsSkeleton from '../components/InternsSkeleton';
import QRCode from '../components/Drawer/DrawerQRCode';
import DrawerAddIntern from '../components/Drawer/DrawerAddIntern';

// MUI imports
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

// Types
import { SessionData } from '../types/sessionData';
import { SlotData } from '../types/slotData';

// Helpers
import { getSession } from '../helpers/api/callSession';
import { getSlot } from '../helpers/api/callSlot';
import { getInternsSigned } from '../helpers/api/callAttendance';
import { handleScroll } from '../helpers/functions/handleScroll';

const Interns = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [scrollPosition, setScrollPosition] = useState<'top' | 'middle' | 'bottom'>('top');
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();
    const smallScreen = useMediaQuery('(max-height:700px)');

    const [session, setSession] = useState<SessionData>({
        id: '',
        trainingLabel: '',
        sessionToken: '',
        sessionName: '',
        sessionBegin: '',
        sessionEnd: '',
        trainerName: '',
        trainees: [],
        slotsId: []
    });

    const [todaySlot, setTodaySlot] = useState<SlotData>({
        id: '',
        date: '',
        startDate: '',
        endDate: ''
    });

    const linkSession = process.env.REACT_APP_QW_SESSION_URL + '/' + session.sessionToken;
    const [internsSigned, setInternsSigned] = useState<number>(0);
    const [colorCountSigned, setColorCountSigned] = useState<string>('#5BB3D9');

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const sessionData = await getSession(id);
                if (!sessionData) {
                    console.error('Aucune donnée de session trouvée');
                    setIsLoading(false);
                    return;
                }
                setSession(sessionData);

                const slotsId = sessionData.slotsId || [];
                const slotPromises = slotsId.map((slotId) => getSlot(slotId));
                const slotDataArray = await Promise.all(slotPromises);

                const validSlotDataArray = slotDataArray.filter((slotData) => slotData !== null) as SlotData[];
                if (validSlotDataArray.length === 0) {
                    console.error('Aucun créneau valide trouvé');
                    setIsLoading(false);
                    return;
                }

                const now = new Date();
                const todayDate = now.toISOString().split('T')[0];

                const todaySlots = validSlotDataArray.filter((slot) => {
                    const startDate = slot.startDate.split('T')[0];
                    const endDate = slot.endDate.split('T')[0];
                    return todayDate >= startDate && todayDate <= endDate;
                });

                let currentSlot = todaySlots.find((slot) => {
                    const startDateTime = new Date(slot.startDate);
                    const endDateTime = new Date(slot.endDate);
                    return now >= startDateTime && now <= endDateTime;
                });

                if (!currentSlot) {
                    currentSlot = validSlotDataArray[0];
                    if (!currentSlot) {
                        console.error('Aucun créneau trouvé');
                        setIsLoading(false);
                        return;
                    }
                }

                const formattedDate = format(new Date(currentSlot.startDate), 'EEEE d MMMM', { locale: fr });
                const formattedStartTime = format(new Date(currentSlot.startDate), 'HH:mm');
                const formattedEndTime = format(new Date(currentSlot.endDate), 'HH:mm');

                setTodaySlot({
                    id: currentSlot.id,
                    date: formattedDate,
                    startDate: formattedStartTime,
                    endDate: formattedEndTime
                });

                const internsSigned = await getInternsSigned(sessionData.id, currentSlot.id);
                setInternsSigned(internsSigned);

                if (internsSigned === sessionData.trainees.length) {
                    setColorCountSigned('#36D476');
                }
            } catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [id]);

    if (isLoading) {
        return <InternsSkeleton />;
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '10vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: 'auto', height: '100%' }}>
                                <Typography sx={{ padding: '1.3em 0', fontSize: '1.2em', color: theme.palette.secondary.main }}>{session.trainingLabel}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.8em', textTransform: 'capitalize' }}>{todaySlot.date} :</Typography>
                                    <Typography sx={{ fontSize: '0.8em', marginLeft: '0.5em', fontWeight: '600' }}>
                                        {todaySlot.startDate} - {todaySlot.endDate}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', width: 'auto', height: '100%' }}>
                                <QRCode link={linkSession} token={session.sessionToken} />
                                <Typography sx={{ fontWeight: '600', color: theme.palette.text.custom }}>{session.sessionToken}</Typography>
                            </Box>
                        </Box>
                    </ContentInfo>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', height: '6vh', marginBottom: '0.5em' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>
                                {internsSigned} / {session.trainees.length}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0 / {session.trainees.length}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}>
                    <Typography sx={{ fontWeight: '600', fontSize: '1.2em', marginBottom: '0.5em', marginLeft: '1em', color: theme.palette.primary.main }}>{i18n.t('Interns.title')}</Typography>
                    {session.trainees.length !== 0 ? (
                        <>
                            <Box
                                className="list-container"
                                onScroll={(event) => handleScroll(event, setScrollPosition)}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    width: '96%',
                                    height: smallScreen ? 'calc(100vh - 6vh - 6vh - 14.25em)' : 'calc(100vh - 6vh - 6vh - 13.5em)',
                                    overflow: 'auto',
                                    marginLeft: 'auto',
                                    whiteSpace: 'pre'
                                }}
                            >
                                {session.trainees.map((trainee, index) => (
                                    <InternsComponent customStyle={index < session.trainees.length - 1} key={trainee} trainee={trainee} todaySlot={todaySlot} nbTrainees={session.trainees.length} />
                                ))}
                            </Box>
                            <DrawerAddIntern buttonStatus="floating" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                        </>
                    ) : (
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: '0 auto', width: '75%', height: '50vh' }}>
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '1.2em',
                                    color: theme.palette.text.primary
                                }}
                            >
                                {i18n.t('Interns.noInterns')}
                            </Typography>
                            <DrawerAddIntern buttonStatus="fixed" scrollPosition={scrollPosition} traineesId={session.trainees} sessionId={session.id} />
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Interns;
