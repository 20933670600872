import React from 'react';
import { Fab, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useTheme } from '../../hooks/useTheme';

interface FloatingButtonProps {
    isExtended: boolean;
    onClick: () => void;
    label: string;
    icon: React.ReactNode;
}

const FloatingButton = ({ isExtended, onClick, label, icon }: FloatingButtonProps) => {
    const theme = useTheme();

    const backgroundColor = theme.palette.mode === 'light' ? '#FFFFFF' : '#15202B';

    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                height: '7em',
                background: `linear-gradient(to top, ${backgroundColor}, transparent)`
            }}
        >
            <Fab
                variant={isExtended ? 'extended' : 'circular'}
                size="medium"
                sx={{
                    position: 'absolute',
                    height: '3.5em',
                    bottom: 20.25,
                    right: 20.25,
                    color: 'white',
                    backgroundColor: '#1A82AF',
                    borderRadius: '0.85em',
                    transition: 'all 0.3s ease-in-out'
                }}
                onClick={onClick}
            >
                {icon}
                {isExtended && label && (
                    <Typography variant="button" sx={{ marginLeft: '0.5em', fontSize: '1.1em', textTransform: 'none' }}>
                        {label}
                    </Typography>
                )}
            </Fab>
        </Box>
    );
};

export default FloatingButton;
