// Types
import { UserData } from '../../types/userData';
import { useUserStore } from '../../store/UserStore';
import { RegistrationData } from '../../types/registrationData';
import { requestHeaders } from './requestHeaders';

// Function to sign up a user
export const signUp = async (registrationData: RegistrationData): Promise<boolean> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/groups/signeval', {
            method: 'POST',
            headers: requestHeaders(),
            body: JSON.stringify(registrationData)
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error signing up:', error);
        return false;
    }
};

// Function to log in a user
export const logIn = async (email: string, password: string): Promise<boolean> => {
    const body = new URLSearchParams();
    body.append('email', email);
    body.append('pwd', password);

    try {
        const response = await fetch((process.env.REACT_APP_QBC_API_URL as string) + '/openid-connect/token', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: body.toString()
        });

        //ajout d'un bout de code qui sert uniquement à la bêta de l'application
        // ==>
        const rawUsers = process.env.REACT_APP_USERS?.replace(/\\"/g, '"');
        const users = JSON.parse(rawUsers as string);
        const goodUser = users.find((user: UserData) => user.email === email);
        // <==

        if (response.ok) {
            const data = await response.json();
            const user: UserData = {
                id: goodUser.id,
                email: email,
                access_token: data.access_token,
                refresh_token: data.refresh_token,
                expires_in: data.expires_in,
                refresh_expires_in: data.refresh_expires_in,
                // ==>
                token: goodUser?.token ?? ''
                // <==
            };

            useUserStore.getState().setUser(user);

            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error logging in:', error);
        return false;
    }
};
