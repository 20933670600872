import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link, useNavigate } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';

// Helpers
import { logIn } from '../../helpers/api/callAuthentification';
import { getUser } from '../../helpers/api/callUser';
import { useUserStore } from '../../store/UserStore';

// Components
import SignEvalTextField from '../../components/SignEvalTextField';
import SignEvalButton from '../../components/SignEvalButton';
import Logo from '../../components/Logo';

// MUI imports
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import InputAdornment from '@mui/material/InputAdornment';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

type FormValues = {
    email: string;
    password: string;
};

const Login = () => {
    const navigate = useNavigate();
    const theme = useTheme();

    const [showPassword, setShowPassword] = useState(false);
    const [passwordFieldFocused, setPasswordFieldFocused] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
        reset
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            const successLogin = await logIn(data.email, data.password);

            if (!successLogin) {
                reset({ password: '' });
                setErrorMessage(i18n.t('Login.error.email-password'));
            }

            const idUser = useUserStore.getState().user.id;
            const user = await getUser(idUser);

            if (user) {
                navigate('/sessions');
            }
        } catch (error) {
            setErrorMessage(i18n.t('Login.error.genericError'));
            reset({ password: '' });
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginLeft: 'auto',
                marginRight: 'auto',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default
            }}
        >
            <Logo size="large" />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-evenly',
                    width: '70%',
                    maxWidth: '25em',
                    height: '20%',
                    maxHeight: '9em'
                }}
                onSubmit={handleSubmit(onSubmit)}
            >
                <SignEvalTextField
                    icon={<MailOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        })
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email')}
                    type="text"
                    error={!!errors.email}
                    helperText={errors.email ? errors.email.message : ''}
                />
                <SignEvalTextField
                    icon={
                        passwordFieldFocused ? (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    sx={{ marginRight: '1px', color: theme.palette.text.primary }}
                                >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                                <LockOutlinedIcon sx={{ color: theme.palette.text.primary }} />
                            </InputAdornment>
                        ) : (
                            <LockOutlinedIcon />
                        )
                    }
                    iconPosition="end"
                    registerProps={{
                        ...register('password', {
                            required: true
                        })
                    }}
                    id="password"
                    label={i18n.t('Login.placeholder.password')}
                    type={showPassword ? 'text' : 'password'}
                    onFocus={() => setPasswordFieldFocused(true)}
                    onBlur={() => setPasswordFieldFocused(false)}
                    error={!!errors.password}
                />
            </Box>
            {errorMessage && (
                <Typography
                    sx={{
                        color: theme.palette.error.main,
                        fontSize: '0.85em',
                        marginbottom: '0.5em',
                        textAlign: 'center'
                    }}
                >
                    {errorMessage}
                </Typography>
            )}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    width: '70%',
                    height: '20%',
                    maxHeight: '9em'
                }}
            >
                {isSubmitting ? (
                    <CircularProgress sx={{ color: theme.palette.text.custom }} />
                ) : (
                    <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                        {i18n.t('Login.link.logIn')}
                    </SignEvalButton>
                )}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '100%'
                    }}
                >
                    <Typography sx={{ fontSize: '0.85em', color: theme.palette.text.primary }}>{i18n.t('Login.withoutAccount')}</Typography>
                    <Link
                        style={{
                            textDecoration: 'underline',
                            color: theme.palette.text.primary
                        }}
                        to="/signup"
                    >
                        <Typography
                            sx={{
                                fontSize: '0.85em',
                                textDecoration: 'underline',
                                color: theme.palette.text.primary
                            }}
                        >
                            {i18n.t('Login.link.signUp')}
                        </Typography>
                    </Link>
                </Box>
                <Link
                    style={{
                        textDecoration: 'underline',
                        color: theme.palette.text.primary
                    }}
                    id="passwordForgotten"
                    to="/password-forgotten"
                >
                    <Typography sx={{ fontSize: '0.85em' }}>{i18n.t('Login.link.passwordForgotten')}</Typography>
                </Link>
            </Box>
        </Box>
    );
};

export default Login;
