import { create } from 'zustand';
import { UserData } from '../types/userData';
import { persist, devtools, StateStorage, createJSONStorage } from 'zustand/middleware';
import Cookies from 'universal-cookie';

type UserStoreType = {
    user: UserData;
    setUser: (user: UserData) => void;
    isAuthenticated: boolean;
    logout: () => void;
};

const initialUser: UserData = {
    id: '',
    firstname: '',
    lastname: '',
    email: '',
    phoneNumber: '',
    trainingCenter: '',
    nullSocietyId: '',
    access_token: '',
    refresh_token: '',
    expires_in: 0,
    refresh_expires_in: 0,
    token: ''
};

const cookies = new Cookies();

const cookieStorage: StateStorage = {
    getItem: (name: string) => {
        try {
            const cookie = cookies.get(name);
            return cookie ? JSON.parse(cookie) : null;
        } catch (error) {
            console.error(`Erreur lors du parsing du cookie ${name}:`, error);
            return null;
        }
    },
    setItem: (name: string, value: any) => {
        try {
            cookies.set(name, JSON.stringify(value), {
                path: '/',
                maxAge: 7 * 24 * 60 * 60, // 7 days in seconds
                secure: true,
                sameSite: 'strict'
            });
        } catch (error) {
            console.error(`Erreur lors de l'écriture du cookie ${name}:`, error);
        }
    },
    removeItem: (name: string) => {
        cookies.remove(name, { path: '/' });
    }
};

export const useUserStore = create<UserStoreType>()(
    devtools(
        persist(
            (set, get) => ({
                user: (cookieStorage.getItem('user-storage') as UserData) || initialUser,
                isAuthenticated: !!(cookieStorage.getItem('user-storage') as UserData)?.access_token,
                setUser: (user: UserData) => {
                    const currentUser = get().user;
                    const updatedUser = { ...currentUser, ...user };
                    set({
                        user: updatedUser,
                        isAuthenticated: updatedUser.access_token !== '' && updatedUser.id !== ''
                    });
                    cookieStorage.setItem('user-storage', JSON.stringify(updatedUser));
                },
                logout: () => {
                    set({
                        user: initialUser,
                        isAuthenticated: false
                    });
                    cookieStorage.removeItem('user-storage');
                }
            }),
            {
                name: 'user-storage',
                storage: createJSONStorage(() => cookieStorage)
            }
        )
    )
);
