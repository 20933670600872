import { useState } from 'react';
import i18n from '../../i18n/i18n';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import { Box, Modal, Typography } from '@mui/material';
import { useUserStore } from '../../store/UserStore';
import SignEvalButton from '../SignEvalButton';
import { useTheme } from '../../hooks/useTheme';

const Logout = () => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(false);
    const logout = useUserStore((state) => state.logout);
    const navigate = useNavigate();

    const handleOpen = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);

    const handleLogout = () => {
        logout();
        toast.success(i18n.t('Logout.logoutSuccess'));
        navigate('/');
        handleClose();
    };

    return (
        <>
            <Box onClick={handleOpen} sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <LogoutOutlinedIcon sx={{ margin: '0.5em 0.75em', color: '#FF6642' }} />
                <Typography sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', color: '#ff6642', textDecoration: 'none' }}>
                    {i18n.t('Menu.logout')}
                </Typography>
            </Box>
            <Modal open={isOpen} onClose={handleClose} aria-labelledby="logout-modal-title" aria-describedby="logout-modal-description">
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '76%',
                        bgcolor: 'background.paper',
                        borderRadius: 3,
                        boxShadow: 24,
                        p: 3
                    }}
                >
                    <LogoutOutlinedIcon sx={{ width: 75, height: 75, color: '#ADADAD' }} />
                    <Typography
                        id="logout-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            mt: 2,
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            textAlign: 'center',
                            color: theme.palette.secondary.main
                        }}
                    >
                        {i18n.t('Logout.title')}
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-around',
                            width: '100%',
                            mt: 3
                        }}
                    >
                        <SignEvalButton variant="contained" backgroundColor="blue" size="medium" onClick={handleClose}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Logout.cancel')}</Typography>
                        </SignEvalButton>
                        <SignEvalButton variant="contained" backgroundColor="red" size="medium" onClick={handleLogout}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Logout.logout')}</Typography>
                        </SignEvalButton>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default Logout;
