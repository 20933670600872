import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import Sessions from './pages/Sessions';
import Session from './pages/Session';
import Login from './pages/Auth/Login';
import SignUp from './pages/Auth/SignUp';
import PasswordForgotten from './pages/Auth/PasswordForgotten';
import Account from './pages/Account/Account';
import Center from './pages/Account/Center';
import AttendanceSheet from './pages/Account/AttendanceSheet';
import Interns from './pages/Interns';

// Theme
import { ThemeProvider } from '@mui/material/styles';
import { useTheme } from './hooks/useTheme';

function App() {
    const theme = useTheme();

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/sessions" element={<Sessions />} />
                    <Route path="/sessions/:id" element={<Session />} />
                    <Route path="/sessions/:id/interns" element={<Interns />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/password-forgotten" element={<PasswordForgotten />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/center" element={<Center />} />
                    <Route path="/attendance-sheet" element={<AttendanceSheet />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
