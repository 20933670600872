import { requestHeaders } from './requestHeaders';
import { TrainingData } from '../../types/trainingData';

export const getTraining = async (): Promise<Array<TrainingData>> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/trainings', {
            method: 'GET',
            headers: requestHeaders()
        });
        const rawTrainings = (await response.json()).data;

        return rawTrainings;
    } catch (error) {
        console.error('Error fetching trainings:', error);
        return [];
    }
};

export const createTraining = async (training: TrainingData): Promise<TrainingData> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/trainings', {
            method: 'POST',
            headers: requestHeaders(),
            body: JSON.stringify(training)
        });
        const data = await response.json();

        return data;
    } catch (error) {
        console.error('Error creating training:', error);
        return training;
    }
};
