import { SessionData } from '../../types/sessionData';

export const sortAscendingSession = (sessions: SessionData[]): SessionData[] => {
    const now = new Date();

    const upcomingSoon: SessionData[] = [];
    const ongoing: SessionData[] = [];
    const upcoming: SessionData[] = [];
    const past: SessionData[] = [];

    sessions.forEach((session) => {
        const sessionStart = new Date(session.sessionBegin.split('/').reverse().join('-'));
        const sessionEnd = new Date(session.sessionEnd.split('/').reverse().join('-'));

        if (sessionStart > now && sessionStart.getTime() - now.getTime() <= 7 * 24 * 60 * 60 * 1000) {
            upcomingSoon.push(session);
        } else if (now >= sessionStart && now <= sessionEnd) {
            ongoing.push(session);
        } else if (sessionStart > now) {
            upcoming.push(session);
        } else {
            past.push(session);
        }
    });

    return [
        ...ongoing.sort((a, b) => new Date(a.sessionBegin.split('/').reverse().join('-')).getTime() - new Date(b.sessionBegin.split('/').reverse().join('-')).getTime()),
        ...upcomingSoon.sort((a, b) => new Date(a.sessionBegin.split('/').reverse().join('-')).getTime() - new Date(b.sessionBegin.split('/').reverse().join('-')).getTime()),
        ...upcoming.sort((a, b) => new Date(a.sessionBegin.split('/').reverse().join('-')).getTime() - new Date(b.sessionBegin.split('/').reverse().join('-')).getTime()),
        ...past.sort((a, b) => new Date(b.sessionEnd.split('/').reverse().join('-')).getTime() - new Date(a.sessionEnd.split('/').reverse().join('-')).getTime())
    ];
};
