import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { RegistrationData } from '../../types/registrationData';

// Components
import SignEvalTextField from '../SignEvalTextField';
import SignEvalButton from '../SignEvalButton';

// MUI imports
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Chakra imports
import { Button, ChakraProvider } from '@chakra-ui/react';
import { CountrySelector, usePhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

type FormValues = RegistrationData;

interface SignUpInfoProps {
    goToNextStep: () => void;
    setRegistrationData: (data: RegistrationData) => void;
}

const SignUpInfo: React.FC<SignUpInfoProps> = ({ goToNextStep, setRegistrationData }) => {
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        clearErrors,
        setValue
    } = useForm<FormValues>();

    const phoneInput = usePhoneInput({
        defaultCountry: 'fr'
    });

    const formatPhoneNumber = (number: string) => {
        const countryCode = phoneInput.country.dialCode;
        const formattedNumber = number.startsWith('0') ? number.slice(1) : number;

        if (!formattedNumber.startsWith(`+${countryCode}`)) {
            return `+${countryCode}${formattedNumber}`;
        }
        return formattedNumber;
    };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const formattedPhone = formatPhoneNumber(data.phoneNumber);
        setValue('phoneNumber', formattedPhone);

        setRegistrationData({ ...data, phoneNumber: formattedPhone });
        goToNextStep();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '70%',
                    maxWidth: '25em',
                    height: '45%',
                    minHeight: '15em',
                    maxHeight: '17em',
                    marginBottom: '0.5em'
                }}
            >
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('firstname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{1,}$/,
                                message: i18n.t('Login.error.firstNamePattern')
                            }
                        }),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            clearErrors('firstname');
                            setValue('firstname', e.target.value);
                        }
                    }}
                    id="firstName"
                    label={i18n.t('Login.placeholder.firstName') + '*'}
                    error={!!errors.firstname}
                    helperText={errors.firstname?.message}
                    type="text"
                />
                <SignEvalTextField
                    icon={<AccountCircleOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('lastname', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ\s]{1,}$/,
                                message: i18n.t('Login.error.lastNamePattern')
                            }
                        }),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            clearErrors('lastname');
                            setValue('lastname', e.target.value);
                        }
                    }}
                    id="lastName"
                    label={i18n.t('Login.placeholder.lastName') + '*'}
                    error={!!errors.lastname}
                    helperText={errors.lastname?.message}
                    type="text"
                />
                <SignEvalTextField
                    icon={<MailOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('email', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
                                message: i18n.t('Login.error.emailPattern')
                            }
                        }),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            clearErrors('email');
                            setValue('email', e.target.value);
                        }
                    }}
                    id="email"
                    label={i18n.t('Login.placeholder.email') + '*'}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    type="email"
                />
                <ChakraProvider>
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <CountrySelector
                            selectedCountry={phoneInput.country.iso2}
                            onSelect={(country) => phoneInput.setCountry(country.iso2)}
                            renderButtonWrapper={({ children, rootProps }) => (
                                <Button
                                    {...rootProps}
                                    variant="outline"
                                    px="4px"
                                    mr="15px"
                                    sx={{
                                        width: '87.5%',
                                        height: '3em',
                                        borderRadius: '10px',
                                        borderColor: theme.palette.mode === 'light' ? '#C9C9C9' : 'grey',
                                        color: theme.palette.text.primary,
                                        backgroundColor: theme.palette.background.default
                                    }}
                                >
                                    {children}
                                </Button>
                            )}
                        />
                        <SignEvalTextField
                            icon={<PhoneOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('phoneNumber', {
                                    required: true,
                                    pattern: {
                                        value: /^[0-9]{10}$/,
                                        message: i18n.t('Login.error.phonePattern')
                                    }
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('phoneNumber');
                                    setValue('phoneNumber', e.target.value);
                                }
                            }}
                            id="phone"
                            label={i18n.t('Login.placeholder.phone') + '*'}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber?.message}
                            type="tel"
                            placeholder={phoneInput.phone}
                        />
                    </Box>
                </ChakraProvider>
                <Typography sx={{ fontSize: '0.7em', marginRight: 'auto', color: theme.palette.text.primary }}>{i18n.t('Login.RequiredFields') + '*'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '70%', height: '18%', maxHeight: '8em' }}>
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {isSubmitting ? i18n.t('Login.loading') : i18n.t('Login.link.next')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
                    <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</Typography>
                    <Link id="signUpButton" to="/">
                        <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary, textDecoration: 'underline' }}>{i18n.t('Login.link.logIn')}</Typography>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default SignUpInfo;
