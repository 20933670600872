import { requestHeaders } from './requestHeaders';
import { SocietyData } from '../../types/societyData';

export const getSocietyByName = async (name: string): Promise<SocietyData | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/societies?name=' + name, {
            method: 'GET',
            headers: requestHeaders()
        });

        const data = (await response.json()).data[0];
        return data;
    } catch (error) {
        console.error('Error getting society by name:', error);
        return null;
    }
};

export const getSocieties = async (): Promise<SocietyData[] | null> => {
    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/societies', {
            method: 'GET',
            headers: requestHeaders()
        });

        const data = await response.json();
        return data.data;
    } catch (error) {
        console.error('Error getting societies:', error);
        return null;
    }
};
