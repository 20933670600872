import React from 'react';

// MUI imports
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const CustomConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 9
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.grey[400],
        borderRadius: 1
    },
    [`&.${stepConnectorClasses.active} .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.text.custom
    },
    [`&.${stepConnectorClasses.completed} .${stepConnectorClasses.line}`]: {
        backgroundColor: theme.palette.text.custom
    }
}));

const CustomStepIcon = styled('div')<{ ownerState: { active: boolean; completed: boolean } }>(({ theme, ownerState }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 22,
    height: 22,
    borderRadius: '50%',
    color: theme.palette.background.default,
    backgroundColor: ownerState.completed || ownerState.active ? theme.palette.text.custom : theme.palette.grey[400],
    transition: 'background-color 0.3s, color 0.3s',
    fontSize: 16,
    marginBottom: '-0.4em'
}));

interface StepperSignInProps {
    activeStep: number;
    steps: string[];
}

const StepperSignIn = ({ activeStep, steps }: StepperSignInProps) => {
    return (
        <Box sx={{ width: '87.5%', margin: '1em 0 0 0' }}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<CustomConnector />}>
                {steps.map((label, index) => (
                    <Step key={label}>
                        <StepLabel
                            StepIconComponent={({ active = false, completed = false }) => (
                                <CustomStepIcon ownerState={{ active, completed }}>{completed ? <CheckIcon fontSize="small" /> : index + 1}</CustomStepIcon>
                            )}
                        >
                            {label}
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};

export default StepperSignIn;
