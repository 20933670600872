import { requestHeaders } from './requestHeaders';
import { useUserStore } from '../../store/UserStore';

export const getUser = async (userId: string | undefined): Promise<boolean> => {
    if (!userId) {
        console.error('userId is null or undefined');
        return false;
    }

    try {
        const userResponse = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/users?id=' + userId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const userData = (await userResponse.json()).data[0];

        const societyResponse = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/societies?id=' + userData.societyId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const societyData = (await societyResponse.json()).data[0];

        if (userResponse.ok) {
            useUserStore.getState().setUser({
                id: userData.id,
                firstname: userData.firstname,
                lastname: userData.lastname,
                phoneNumber: userData.phoneNumber,
                trainingCenter: societyData.name
            });
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error('Error getting user:', error);
        return false;
    }
};
