import { useEffect, useState } from 'react';
import i18n from '../../i18n/i18n';
import { Controller, useForm, SubmitHandler } from 'react-hook-form';

// Components
import TitleContentInfo from '../TitleContentInfo';
import SignEvalTextField from '../SignEvalTextField';
import SignEvalButton from '../SignEvalButton';
import FloatingButton from '../Button/FloatingButton';

// MUI imports
import Drawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete } from '@mui/material';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import Button from '@mui/material/Button';

// Helper
import { useTheme } from '../../hooks/useTheme';
import { getSocietyByName, getSocieties } from '../../helpers/api/callSociety';
import { getInternByInfo, createIntern } from '../../helpers/api/callIntern';
import { updateTraineesSession } from '../../helpers/api/callSession';

// Types
import { UserData } from '../../types/userData';
import { SocietyData } from '../../types/societyData';
import { useUserStore } from '../../store/UserStore';

interface DrawerCreateSessionProps {
    buttonStatus: 'floating' | 'fixed';
    scrollPosition: 'top' | 'middle' | 'bottom';
    traineesId: string[];
    sessionId: string;
}

type FormValues = UserData;

const DrawerAddIntern = ({ buttonStatus, scrollPosition, traineesId, sessionId }: DrawerCreateSessionProps) => {
    const theme = useTheme();
    const { user, setUser } = useUserStore();
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [societies, setSocieties] = useState<SocietyData[] | null>(null);

    const fetchSocieties = async () => {
        const societies = await getSocieties();
        setSocieties(societies);
    };

    const handleOpenDrawer = () => {
        setIsDrawerOpen(true);
        fetchSocieties();
    };

    const handleCloseDrawer = () => {
        resetField('civility');
        resetField('firstname');
        resetField('lastname');
        resetField('societyId');
        resetField('email');
        resetField('registrationNumber');
        setError(null);
        setIsDrawerOpen(false);
    };

    const civilityOptions = i18n.language === 'fr-FR' ? ['M.', 'Mme', 'Autre'] : ['Mr.', 'Mrs.', 'Other'];

    const {
        register,
        handleSubmit,
        control,
        resetField,
        formState: { errors },
        clearErrors,
        setValue
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setIsLoading(true);
        let internId: string = '';
        try {
            // If the societyId is not set by the user, we set it with the userStore's societyId
            if (!data.societyId) {
                const society = user.nullSocietyId;
                data.societyId = society?.toString();

                // If the userStore's societyId is not set, we set it with the society N/A
                if (!data.societyId) {
                    const society = await getSocietyByName('N/A');
                    data.societyId = society?.id?.toString() as string;
                    setUser({ nullSocietyId: data.societyId });
                }
            }

            // Check if the intern already exists
            const intern = await getInternByInfo(data.firstname as string, data.lastname as string, data.societyId as string);

            // If the intern does not exist, we create it
            if (!intern) {
                const response = await createIntern(
                    data.civility as string,
                    data.firstname as string,
                    data.lastname as string,
                    data.societyId as string,
                    data.email as string,
                    data.registrationNumber as string
                );

                if (response.status !== 201) {
                    setError(i18n.t('AddIntern.errors.createIntern'));
                    setIsLoading(false);
                    return;
                }

                // We get the intern's id
                internId = String(response.id!);
            } else {
                // If the intern exists, we get its id
                internId = String(intern.id!);
            }

            // Check if the intern is already added to the session
            if (traineesId.some((id) => id === internId)) {
                setError(i18n.t('AddIntern.errors.alreadyAdded'));
                setIsLoading(false);
                return;
            }

            // We update the session with the new intern
            const responseUpdateSession = await updateTraineesSession(sessionId, [...traineesId, internId]);

            if (responseUpdateSession.status === 403) {
                setError(i18n.t('AddIntern.errors.existingEvaluations'));
                setIsLoading(false);
                return;
            }

            if (responseUpdateSession.status === 405) {
                setError(i18n.t('AddIntern.errors.updateSession'));
                setIsLoading(false);
                return;
            }

            handleCloseDrawer();
            setIsLoading(false);
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    return (
        <>
            {buttonStatus === 'floating' ? (
                <FloatingButton isExtended={scrollPosition === 'top'} onClick={handleOpenDrawer} label={i18n.t('AddIntern.title')} icon={<AddCircleOutlineIcon />} />
            ) : (
                <Button variant="contained" sx={{ height: '3.5em', marginTop: '1em', color: 'white', backgroundColor: '#1A82AF', borderRadius: '0.85em' }} onClick={handleOpenDrawer}>
                    <AddCircleOutlineIcon />
                    <Typography variant="button" sx={{ marginLeft: '0.5em', fontSize: '1.1em', textTransform: 'none' }}>
                        {i18n.t('AddIntern.title')}
                    </Typography>
                </Button>
            )}
            <Drawer
                anchor="bottom"
                PaperProps={{
                    sx: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        borderRadius: '15px 15px 0 0',
                        paddingBottom: '1em',
                        width: '100%',
                        height: 'auto',
                        backgroundColor: theme.palette.background.default,
                        backgroundImage: 'none'
                    }
                }}
                open={isDrawerOpen}
                onClose={handleCloseDrawer}
            >
                <IconButton onClick={handleCloseDrawer} sx={{ alignSelf: 'flex-end', margin: '0.5em' }}>
                    <CloseIcon sx={{ color: theme.palette.text.custom, fontSize: '1.05em' }} />
                </IconButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', width: '87%', color: '#000', marginTop: '-1em' }}>
                    <TitleContentInfo title={i18n.t('AddIntern.title')} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', margin: '0.5em auto 0 auto', width: '100%', height: '25em' }}>
                        <Controller
                            name="civility"
                            defaultValue={civilityOptions[0]}
                            control={control}
                            render={({ field }) => (
                                <FormControl fullWidth>
                                    <InputLabel
                                        id="demo-simple-select-label"
                                        sx={{
                                            fontSize: '0.85em',
                                            color: theme.palette.text.primary,
                                            '&.Mui-focused': { color: theme.palette.text.custom }
                                        }}
                                    >
                                        {i18n.t('AddIntern.inputs.civility')}
                                    </InputLabel>
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-label"
                                        label={i18n.t('AddIntern.inputs.civility')}
                                        onChange={(e) => field.onChange(e.target.value)}
                                        sx={{
                                            maxHeight: '3em',
                                            borderRadius: '10px',
                                            color: theme.palette.text.primary,
                                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                borderColor: theme.palette.text.custom
                                            }
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    boxShadow: 'none',
                                                    backgroundColor: 'transparent'
                                                }
                                            },
                                            MenuListProps: {
                                                sx: {
                                                    width: '100%',
                                                    backgroundColor: theme.palette.background.paper,
                                                    color: theme.palette.text.primary,
                                                    borderRadius: '20px',
                                                    marginTop: '0.5em'
                                                }
                                            }
                                        }}
                                    >
                                        {civilityOptions.map((option) => (
                                            <MenuItem sx={{ margin: '0 auto', width: '95%', borderRadius: '10px' }} key={option} value={option}>
                                                {option}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        />
                        <SignEvalTextField
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('firstname', {
                                    required: true,
                                    pattern: {
                                        value: /^[a-zA-ZÀ-ÿ\s]{1,}$/,
                                        message: i18n.t('AddIntern.errors.firstName')
                                    }
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('firstname');
                                    setError(null);
                                    setValue('firstname', e.target.value);
                                }
                            }}
                            id="sessionName"
                            label={i18n.t('AddIntern.inputs.firstName') + ' *'}
                            error={!!errors.firstname}
                            type="text"
                        />
                        <SignEvalTextField
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('lastname', {
                                    required: true,
                                    pattern: {
                                        value: /^[a-zA-ZÀ-ÿ\s]{1,}$/,
                                        message: i18n.t('AddIntern.errors.lastName')
                                    }
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('lastname');
                                    setError(null);
                                    setValue('lastname', e.target.value);
                                }
                            }}
                            id="sessionName"
                            label={i18n.t('AddIntern.inputs.lastName') + ' *'}
                            error={!!errors.lastname}
                            type="text"
                        />
                        <Controller
                            name="societyId"
                            control={control}
                            defaultValue={undefined}
                            render={({ field }) => (
                                <Autocomplete
                                    {...field}
                                    options={societies?.map((society) => society.name) || []}
                                    noOptionsText={i18n.t('CreateSession.errors.noTrainings')}
                                    onChange={(e, data) => {
                                        const society = societies?.find((society) => society.name === data);
                                        field.onChange(society?.id || null);
                                    }}
                                    renderInput={(params) => (
                                        <SignEvalTextField
                                            {...params}
                                            label={i18n.t('AddIntern.inputs.society')}
                                            InputProps={{
                                                ...params.InputProps,
                                                sx: {
                                                    maxHeight: '3em',
                                                    borderRadius: '10px',
                                                    color: theme.palette.text.primary
                                                }
                                            }}
                                            InputLabelProps={{
                                                sx: { fontSize: '0.85em', color: theme.palette.text.primary }
                                            }}
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            width: '100%',
                                            backgroundColor: theme.palette.background.paper,
                                            color: theme.palette.text.primary,
                                            borderRadius: '10px',
                                            marginTop: '0.5em',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
                                            maxHeight: '9em',
                                            overflow: 'auto',
                                            listStyle: 'none',
                                            position: 'absolute'
                                        }
                                    }}
                                />
                            )}
                        />

                        <SignEvalTextField
                            icon={<MailOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('email', {
                                    required: false,
                                    pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('email');
                                    setError(null);
                                    setValue('email', e.target.value);
                                }
                            }}
                            id="sessionName"
                            label={i18n.t('AddIntern.inputs.email')}
                            error={!!errors.email}
                            type="email"
                        />
                        <SignEvalTextField
                            icon={<AccountCircleOutlinedIcon />}
                            iconPosition="end"
                            registerProps={{
                                ...register('registrationNumber', {
                                    required: false,
                                    pattern: /^[a-zA-Z0-9]{1,}$/
                                }),
                                onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                                    clearErrors('registrationNumber');
                                    setError(null);
                                    setValue('registrationNumber', e.target.value);
                                }
                            }}
                            id="sessionName"
                            label={i18n.t('AddIntern.inputs.registrationNumber')}
                            error={!!errors.registrationNumber}
                            type="text"
                        />
                        <Typography sx={{ textAlign: 'center', fontSize: '0.7em', color: theme.palette.error.main }}>{error}</Typography>
                        <Typography sx={{ fontSize: '0.7em', marginRight: 'auto', color: theme.palette.text.primary }}>{i18n.t('Login.RequiredFields') + '*'}</Typography>
                    </Box>
                    {isLoading ? (
                        <CircularProgress sx={{ margin: '1.5em auto 1em auto', height: '3em', color: theme.palette.text.custom }} />
                    ) : (
                        <SignEvalButton sx={{ margin: '1.5em 0 1em 0' }} size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                            {i18n.t('AddIntern.button.add')}
                        </SignEvalButton>
                    )}
                </Box>
            </Drawer>
        </>
    );
};

export default DrawerAddIntern;
