import { SlotData } from '../../types/slotData';
import { requestHeaders } from './requestHeaders';

export const getSlot = async (slotId: string | undefined): Promise<SlotData | null> => {
    if (!slotId) {
        console.error('slotId is null or undefined');
        return null;
    }

    try {
        const response = await fetch((process.env.REACT_APP_QW_API_URL as string) + '/slots?id=' + slotId, {
            method: 'GET',
            headers: requestHeaders()
        });
        const data = (await response.json()).data[0];

        return {
            id: data.id,
            startDate: data.startDate,
            endDate: data.endDate
        };
    } catch (error) {
        console.error('Error getting slot:', error);
        return null;
    }
};
