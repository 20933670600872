import React from 'react';
import { TextField, TextFieldProps, InputAdornment } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '../hooks/useTheme';

interface SignEvalTextFieldProps extends Omit<TextFieldProps, 'variant'> {
    icon?: React.ReactNode;
    iconPosition?: 'start' | 'end';
    registerProps?: Record<string, unknown>;
    fullWidth?: boolean;
}

const SignEvalTextFieldStyled = styled(TextField)(({ theme }) => ({
    textAlign: 'center',
    width: '100%',
    margin: '0',
    '& .MuiInputBase-root': {
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary
    },
    '& .MuiInputLabel-root': {
        color: theme.palette.text.primary
    },
    '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.text.custom
        }
    },
    '& label.Mui-focused': {
        color: theme.palette.text.custom
    }
}));

const SignEvalTextField: React.FC<SignEvalTextFieldProps> = ({ children, icon, iconPosition = 'start', registerProps, ...props }) => {
    const theme = useTheme();

    const adornment = (
        <InputAdornment position={iconPosition} sx={{ color: theme.palette.text.primary }}>
            {icon}
        </InputAdornment>
    );
    return (
        <SignEvalTextFieldStyled
            size="small"
            margin="normal"
            fullWidth={props.fullWidth}
            InputProps={{
                sx: { borderRadius: '10px', minHeight: '3em' },
                [iconPosition === 'start' ? 'startAdornment' : 'endAdornment']: adornment
            }}
            InputLabelProps={{ sx: { fontSize: '0.85em', padding: '6px 5px 5px 5px' } }}
            {...props}
            {...registerProps}
        >
            {children}
        </SignEvalTextFieldStyled>
    );
};

export default SignEvalTextField;
