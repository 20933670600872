import React, { useEffect, useState } from 'react';
import { useTheme } from '../hooks/useTheme';
import { useParams } from 'react-router-dom';
import i18n from '../i18n/i18n';

// Components
import Avatar from './Avatar';
import SignButton from './SignButton';
import EvaluateButton from './EvaluateButton';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

// Helpers
import { getInternById } from '../helpers/api/callIntern';

// Types
import { InternData } from '../types/internData';
import { SlotData } from '../types/slotData';

interface SingleTraineeProps {
    trainee: string;
    todaySlot: SlotData;
    customStyle: boolean;
    nbTrainees?: number;
}

const SingleTrainee: React.FC<SingleTraineeProps> = ({ trainee, todaySlot, customStyle, nbTrainees }) => {
    const theme = useTheme();
    const [intern, setIntern] = useState<InternData | null>(null);
    const { id } = useParams<{ id: string }>();

    const borderColor = theme.palette.mode === 'light' ? '#EFEFEF' : '#303234';

    useEffect(() => {
        const fetchIntern = async () => {
            const internData = await getInternById(trainee);
            if (internData) {
                setIntern(internData);
            }
        };

        fetchIntern();
    }, [trainee]);

    if (!intern) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '10vh',
                    borderTop: `1px solid ${borderColor}`
                }}
            >
                <CircularProgress sx={{ color: theme.palette.text.custom }} />
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '100%',
                    padding: '7.5px 0',
                    color: theme.palette.secondary.main,
                    backgroundColor: theme.palette.background.default,
                    borderTop: `1px solid ${borderColor}`,
                    paddingBottom: customStyle ? '7.5px' : '2em'
                }}
            >
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar firstName={intern.firstname} lastName={intern.lastname} size="medium" />
                    <Typography variant="subtitle1" sx={{ marginLeft: '1em' }}>
                        {intern.firstname} {intern.lastname}
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <SignButton route="/interns/sign" internId={intern.id} sessionId={id} todaySlot={todaySlot} />
                    <EvaluateButton route="/interns/sign" internId={intern.id} sessionId={id} />
                </Box>
            </Box>
            {!customStyle && (
                <Typography variant="subtitle1" sx={{ paddingBottom: '1em', zIndex: 1, color: theme.palette.text.primary }}>
                    {nbTrainees && nbTrainees > 1 ? `${nbTrainees} ${i18n.t('Interns.interns', { count: nbTrainees })}` : '1 ' + i18n.t('Interns.intern')}
                </Typography>
            )}
        </>
    );
};

export default SingleTrainee;
