import i18n from '../../i18n/i18n';
import { useTheme } from '../../hooks/useTheme';
import SignEvalButton from '../SignEvalButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Fab from '@mui/material/Fab';
import CircularProgress from '@mui/material/CircularProgress';
import { blue, green, red } from '@mui/material/colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

interface MessageBoxProps {
    message: string;
    buttonText?: string;
    buttonAction?: () => void;
}

interface SignUpLoadingProps {
    isSuccess: boolean;
    isFailed: boolean;
    isLoading: boolean;
}

const SignUpLoading = ({ isSuccess, isFailed, isLoading }: SignUpLoadingProps) => {
    const theme = useTheme();

    const bgLoaderColor = theme.palette.mode === 'dark' ? '#15202B' : '#FFFFFF';
    const buttonSx = {
        bgcolor: isSuccess ? green[500] : isFailed ? red[500] : bgLoaderColor,
        color: 'white',
        boxShadow: 'none'
    };

    const MessageBox = ({ message, buttonText, buttonAction }: MessageBoxProps) => (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '80%',
                maxWidth: '30em',
                fontSize: '0.8em',
                textAlign: 'center',
                color: 'text.primary'
            }}
        >
            <Typography sx={{ fontSize: '1.1em' }}>{message}</Typography>
            {buttonText && buttonAction && (
                <SignEvalButton sx={{ position: 'absolute', bottom: '1.75em', width: '70%' }} size="large" backgroundColor="blue" onClick={buttonAction}>
                    {buttonText}
                </SignEvalButton>
            )}
        </Box>
    );

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                height: '20%',
                minHeight: '10em',
                maxHeight: '12em',
                marginTop: '-1em'
            }}
        >
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1.5em' }}>
                <Box sx={{ m: 1, position: 'relative' }}>
                    <Fab sx={buttonSx}>{isSuccess ? <CheckIcon /> : isFailed ? <CloseIcon /> : null}</Fab>
                    {isLoading && <CircularProgress size={68} sx={{ color: blue[400], position: 'absolute', top: -6, left: -6, zIndex: 1 }} />}
                </Box>
            </Box>
            {isSuccess && <MessageBox message={i18n.t('Login.loader.successMessage')} buttonText={i18n.t('Login.link.logIn')} buttonAction={() => window.location.replace('/')} />}
            {isFailed && <MessageBox message={i18n.t('Login.loader.failedMessage')} buttonText={i18n.t('Login.link.retry')} buttonAction={() => window.location.replace('/signup')} />}
            {!isSuccess && !isFailed && <MessageBox message={`${i18n.t('Login.loader.loadingMessage')}\n${i18n.t('Login.loader.pleaseWait')}`} />}
        </Box>
    );
};

export default SignUpLoading;
