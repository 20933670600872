import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import i18n from '../../i18n/i18n';
import { Link } from 'react-router-dom';
import { useTheme } from '../../hooks/useTheme';
import { RegistrationData } from '../../types/registrationData';

// Components
import SignEvalTextField from '../SignEvalTextField';
import SignEvalButton from '../SignEvalButton';

// MUI imports
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type FormValues = RegistrationData;

interface SignUpCenterProps {
    goToNextStep: () => void;
    setRegistrationData: (data: RegistrationData) => void;
}

const SignUpCenter: React.FC<SignUpCenterProps> = ({ goToNextStep, setRegistrationData }) => {
    const theme = useTheme();

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        clearErrors,
        setValue
    } = useForm<FormValues>();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        setRegistrationData(data);
        goToNextStep();
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    width: '70%',
                    maxWidth: '25em',
                    height: '22%',
                    maxHeight: '9em',
                    marginBottom: '1em'
                }}
            >
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('label', {
                            required: true,
                            pattern: {
                                value: /^[a-zA-ZÀ-ÿ0-9\s'":]{1,}$/,
                                message: i18n.t('Login.error.trainingCenterPattern')
                            }
                        }),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            clearErrors('label');
                            setValue('label', e.target.value);
                        }
                    }}
                    id="trainingCenter"
                    label={i18n.t('Login.placeholder.trainingCenter') + '*'}
                    error={!!errors.label}
                    helperText={errors.label?.message}
                    type="text"
                />
                <SignEvalTextField
                    icon={<HomeWorkOutlinedIcon />}
                    iconPosition="end"
                    registerProps={{
                        ...register('siret', {
                            required: false,
                            pattern: {
                                value: /^[0-9]{14}$/,
                                message: i18n.t('Login.error.siretNumberPattern')
                            }
                        }),
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                            clearErrors('siret');
                            setValue('siret', e.target.value);
                        }
                    }}
                    id="siretNumber"
                    label={i18n.t('Login.placeholder.siretNumber')}
                    error={!!errors.siret}
                    helperText={errors.siret?.message}
                    type="text"
                />
                <Typography sx={{ fontSize: '0.7em', marginRight: 'auto', color: theme.palette.text.primary }}>{i18n.t('Login.RequiredFields') + '*'}</Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-around', width: '70%', height: '18%', maxHeight: '8em' }}>
                <SignEvalButton size="large" backgroundColor="blue" onClick={handleSubmit(onSubmit)}>
                    {isSubmitting ? i18n.t('Login.loading') : i18n.t('Login.link.next')}
                </SignEvalButton>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: 'auto' }}>
                    <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary }}>{i18n.t('Login.withAccount')}</Typography>
                    <Link id="signUpButton" to="/">
                        <Typography sx={{ fontSize: '0.8em', marginBottom: '0.4em', color: theme.palette.text.primary, textDecoration: 'underline' }}>{i18n.t('Login.link.logIn')}</Typography>
                    </Link>
                </Box>
            </Box>
        </>
    );
};

export default SignUpCenter;
