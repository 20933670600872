import { useState } from 'react';
import { useTheme } from '../../hooks/useTheme';
import i18n from '../../i18n/i18n';
import { RegistrationData } from '../../types/registrationData';

// Components
import Logo from '../../components/Logo';
import StepperSignIn from '../../components/auth/StepperSignIn';
import SignUpInfo from '../../components/auth/SignUpInfo';
import SignUpCenter from '../../components/auth/SignUpCenter';
import SignUpPasswords from '../../components/auth/SignUpPasswords';
import SignUpLoading from '../../components/auth/SignUpLoading';

// MUI imports
import Box from '@mui/material/Box';

// Helpers
import { signUp } from '../../helpers/api/callAuthentification';

const SignUp = () => {
    const theme = useTheme();
    const steps = [i18n.t('Login.Steps.step1'), i18n.t('Login.Steps.step2'), i18n.t('Login.Steps.step3'), i18n.t('Login.Steps.step4')];
    const [activeStep, setActiveStep] = useState(0);
    const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);
    const [isFailed, setIsFailed] = useState(false);

    const goToNextStep = () => setActiveStep((prev) => prev + 1);

    const handleSetRegistrationData = (newData: Partial<RegistrationData>) => {
        setRegistrationData(
            (prevData) =>
                ({
                    ...prevData,
                    ...newData
                }) as RegistrationData
        );
    };

    const handleSignUp = async (registrationData: Partial<RegistrationData>) => {
        try {
            const response = await signUp(registrationData as RegistrationData);
            setIsSuccess(!!response);
            setIsFailed(!response);
        } catch (error) {
            setIsFailed(true);
        } finally {
            setIsLoading(false);
        }
    };

    const renderStepContent = (step: number) => {
        switch (step) {
            case 0:
                return <SignUpInfo goToNextStep={goToNextStep} setRegistrationData={handleSetRegistrationData} />;
            case 1:
                return <SignUpCenter goToNextStep={goToNextStep} setRegistrationData={handleSetRegistrationData} />;
            case 2:
                return <SignUpPasswords goToNextStep={goToNextStep} registrationData={registrationData} submitSignUp={handleSignUp} />;
            case 3:
                return <SignUpLoading isSuccess={isSuccess} isFailed={isFailed} isLoading={isLoading} />;
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary
            }}
        >
            <Logo size="medium" />
            <StepperSignIn activeStep={activeStep} steps={steps} />
            {renderStepContent(activeStep)}
        </Box>
    );
};

export default SignUp;
