import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../i18n/i18n';

// Components
import HeaderBar from '../components/HeaderBar';
import ContentInfo from '../components/ContentInfo';
import SignEvalButton from '../components/SignEvalButton';
import DeleteSession from '../components/Modals/DeleteSession';
import SessionSkeleton from '../components/SessionSkeleton';
import QRCode from '../components/Drawer/DrawerQRCode';

// MUI imports
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import GestureOutlinedIcon from '@mui/icons-material/GestureOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import HttpsOutlinedIcon from '@mui/icons-material/HttpsOutlined';

// Helpers
import { useTheme } from '../hooks/useTheme';
import { getSession } from '../helpers/api/callSession';

// Types
import { SessionData } from '../types/sessionData';

const Session: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const { id } = useParams<{ id: string }>();
    const theme = useTheme();

    const [internsSigned, setInternsSigned] = useState<number>(0);
    const [colorCountSigned, setColorCountSigned] = useState<string>('#5BB3D9');

    const [session, setSession] = useState<SessionData>({
        id: '',
        trainingLabel: '',
        sessionToken: '',
        sessionName: '',
        sessionBegin: '',
        sessionEnd: '',
        trainerName: '',
        trainees: Array<string>(),
        slotsId: Array<string>()
    });

    const linkSession = process.env.REACT_APP_QW_SESSION_URL + '/' + session.sessionToken;

    useEffect(() => {
        const fetchSession = async () => {
            if (id) {
                try {
                    const sessionData = await getSession(id);
                    if (sessionData) {
                        setSession(sessionData);
                    }
                } catch (error) {
                    console.error('Error fetching session:', error);
                }
                setLoading(false);
            } else {
                console.error('id is null or undefined');
                setLoading(false);
            }
        };

        fetchSession();
    }, [id]);

    if (loading) {
        return <SessionSkeleton />;
    }

    return (
        <Box sx={{ width: '100%', height: '100vh', alignItems: 'center', backgroundColor: theme.palette.background.default, color: theme.palette.text.primary }}>
            <HeaderBar />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 auto',
                    width: '100%',
                    height: '92.5%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '92.5%',
                        margin: '0 auto'
                    }}
                >
                    <ContentInfo margin="0.5em 0 0.3em 0" padding="0.5em 0">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                width: '92.5%',
                                minHeight: '13vh'
                            }}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', width: '80%', height: '100%' }}>
                                <Typography sx={{ padding: '0.7em 0', fontSize: '1.15em', color: theme.palette.secondary.main }}>{session.trainingLabel}</Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-evenly',
                                        width: 'auto',
                                        color: theme.palette.text.primary
                                    }}
                                >
                                    <Typography sx={{ fontSize: '0.85em' }}>{session.sessionName}</Typography>
                                    <Typography sx={{ fontSize: '0.85em' }}>{`${session.sessionBegin} - ${session.sessionEnd}`}</Typography>
                                    <Typography sx={{ fontSize: '0.85em' }}>{session.trainerName}</Typography>
                                </Box>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    width: '18%',
                                    margin: '0 0.2em',
                                    height: '100%'
                                }}
                            >
                                <QRCode link={linkSession} token={session.sessionToken} />
                                <Typography sx={{ fontWeight: '600', color: theme.palette.text.custom }}>{session.sessionToken}</Typography>
                            </Box>
                        </Box>
                    </ContentInfo>
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', alignItems: 'center', width: '100%', marginTop: '0.5em' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#FFB664' }}>
                            <PersonOffOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>0</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: colorCountSigned }}>
                            <GestureOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>
                                {internsSigned} / {session.trainees.length}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', color: '#5BB3D9' }}>
                            <InventoryOutlinedIcon sx={{ marginRight: '0.2em', fontSize: '1.75em' }} />
                            <Typography sx={{ fontSize: '1.3em' }}>{`0 / ${session.trainees.length}`}</Typography>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', width: '100%', height: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-around',
                            alignItems: 'center',
                            width: '92.5%',
                            height: '45%',
                            maxHeight: '24vh',
                            marginTop: '0.25em'
                        }}
                    >
                        <SignEvalButton route={`/sessions/${session.id}/interns`} variant="contained" size="large" backgroundColor="blue" startIcon={<PeopleAltOutlinedIcon />}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.trainees')}</Typography>
                            {session.trainees.length}
                        </SignEvalButton>
                        <SignEvalButton variant="contained" size="large" backgroundColor="blue" startIcon={<GestureOutlinedIcon />}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.signature')}</Typography>
                            &nbsp;
                        </SignEvalButton>
                        <SignEvalButton variant="contained" size="large" backgroundColor="blue" startIcon={<InventoryOutlinedIcon />}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.evaluation')}</Typography>
                            &nbsp;
                        </SignEvalButton>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '92.5%',
                            marginBottom: '3em'
                        }}
                    >
                        <DeleteSession sessionId={id} sessionName={session.trainingLabel} />
                        <SignEvalButton id="closeButton" variant="contained" backgroundColor="purple" size="medium" endIcon={<HttpsOutlinedIcon />}>
                            <Typography sx={{ color: theme.palette.text.secondary }}>{i18n.t('Session.actions.close')}</Typography>
                        </SignEvalButton>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Session;
