export const handleScroll = (event: React.UIEvent<HTMLDivElement>, setScrollPosition: React.Dispatch<React.SetStateAction<'top' | 'bottom' | 'middle'>>): void => {
    const target = event.target as HTMLDivElement;
    const { scrollTop, scrollHeight, clientHeight } = target;

    if (scrollTop === 0) {
        setScrollPosition('top');
    } else if (scrollTop + clientHeight >= scrollHeight * 0.95) {
        setScrollPosition('bottom');
    } else {
        setScrollPosition('middle');
    }
};
